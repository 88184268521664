import React from 'react';

import Layout from '../components/Layout';

const ThankYouPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Thank you for contacting us!</h2>
        <p>We'll be in touch soon.</p>
        <ul className="actions special">
            <li>
              <a href="/#" className="button primary">
                Return Home
              </a>
            </li>
          </ul>
      </header>
      <section className="wrapper style4">
      </section>
    </article>
  </Layout>
);

export default ThankYouPage;
